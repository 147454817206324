/* Navbar.module.css */
.navbar {
  z-index: 99999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  color: white;
  top: 0;
  position: fixed;
  z-index: 99;
}

@media (max-width: 768px) {
  .navbarLinks {
    display: none;
  }
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  /* Ensure it stays above other content */
}

.navLinks {
  display: flex;
  flex-flow: row;
  position: absolute;
  right: 5vw;
  top: 12px;
  gap: 1.5rem;
  /* Space between links */
}

.navLink {
  text-decoration: none;
  color: #fff;
  /* Default link color */
  font-weight: 500;
  cursor: pointer;
  padding: 16px 8px;
  transition: color 0.3s ease-in-out;
}

.navLink:hover {
  color: #d49c4d;
  /* Change color on hover (adjust as needed) */
}

@media (max-width: 768px) {
  .navLinks {
    gap: 12px;
  }
}
