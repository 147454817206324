/* Logo.module.css */

.logo {
  height: 32px;
  width: 64px;
  z-index: 9999;
  position: relative;
}
.logoWrapper {
  transform-origin: left bottom;
}
