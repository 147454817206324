@charset "UTF-8";
/* values css */
.marequeeText {
  font-size: 2rem;
}

.marequeeText h3 {
  display: inline-block;
}

.valuesGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  color: #fff;
  width: 100vw;
  padding: 0 5vw;
  margin-top: 50px;
}

.valuesGrid p {
  line-height: 1.5rem;
}

.valuesGrid b {
  font-weight: bold;
}

.valuesText  {
  width: 100%;
}

.small-value {
  padding: 0;
}

.titleWrapper {
  position: relative;
}

.titleWrapper svg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.iconWrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
}

.icon02 {
  transform: scale(0.5) translateY(-150px) translateX(40%);
}

.icon03 {
  transform: scale(0.7) translateY(-170px);
}
