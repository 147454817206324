/* Empty css */
.canvasContainer {
  height: 150vh;
  position: relative;
}

.canvasWrapper {
  position: relative;
}

.canvasContainer:after {
  content: '';
  pointer-events: none;
  width: 105%;
  position: fixed;
  height: 150vh;
  top: -2.5%;
  left: 0;
}

.textOverlay {
  position: absolute;
  top: 50vh;
  z-index: 10;
  left: 5vw;
}

.textOverlay h4 {
  font-stretch: expanded;
  font-weight: bold;
  font-size: 3rem;
  color: #fff;
}

.skyrocketList {
  display: flex;
  flex-flow: row;
  width: 90vw;
  margin-top: 128px;
  gap: 48px;
}

.skyrocketListSingle {
  width: 25%;
  color: #fff;
}

.skyrocketListSingle h5 {
  font-weight: normal;
  font-size: 1.5rem;
}

.skyrocketListSingle p {
  margin-top: 8px;
  line-height: 1.25em;
  font-weight: 300 !important;
  color: rgba(255, 255, 255, 0.7);
}

@media (max-width: 768px) {
  .skyrocketList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    width: 90vw;
    margin-top: 48px;
    gap: 48px;
  }
  .skyrocketListSingle {
    width: 100%;
    color: #fff;
  }
}
