@import url(_reset);
@font-face {
  font-family: 'Mosvita';
  src: url("./assets/font/Mosvita-Regular.woff2") format("woff2");
  font-weight: 400;
  /* Regular */
  font-style: normal;
}

@font-face {
  font-family: 'Mosvita';
  src: url("./assets/font/Mosvita-Light.woff2") format("woff2");
  font-weight: 300;
  /* Light */
  font-style: normal;
}

@font-face {
  font-family: 'Mosvita';
  src: url("./assets/font/Mosvita-Medium.woff2") format("woff2");
  font-weight: 500;
  /* Medium */
  font-style: normal;
}

@font-face {
  font-family: 'Mosvita';
  src: url("./assets/font/Mosvita-Bold.woff2") format("woff2");
  font-weight: 700;
  /* Bold */
  font-style: normal;
}

@font-face {
  font-family: 'Mosvita';
  src: url("./assets/font/Mosvita-BoldExpanded.woff2") format("woff2");
  font-weight: 700;
  /* Bold Expanded */
  font-style: normal;
}

@font-face {
  font-family: 'Mosvita';
  src: url("./assets/font/Mosvita-SemiBoldExpanded.woff2") format("woff2");
  font-weight: 600;
  /* Semi-Bold Expanded */
  font-style: normal;
}

.canvasHero:after {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, black 5%, transparent 25%);
}

* {
  box-sizing: border-box;
}

html,
body {
  overflow-x: hidden;
}

html,
body,
#root {
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: black;
  font-family: 'Mosvita', serif;
  width: 100vw;
  overflow-x: hidden;
}

.canvasHero {
  position: absolute !important;
  top: 0;
  height: 200vh !important;
  z-index: 0;
  left: 0;
}

.charWrapper {
  display: inline-block;
  width: auto;
  transform: translateY(100);
}

#sine-wave {
  position: absolute;
  width: 100vw;
  height: 20px;
}

.dot {
  position: absolute;
  width: 4px;
  height: 4px;
  background-color: #61dafb;
  border-radius: 50%;
}

.rowitem .globalModifier {
  width: 25vw !important;
  padding: 0 !important;
}

.contactitem .globalModifier {
  width: 45vw !important;
  padding: 0 !important;
}

.contactForm .globalModifier {
  width: 100%;
  padding: 0;
}

.secondSection {
  z-index: 10;
  position: relative;
  pointer-events: none;
  width: 100vw;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 128px 0;
  flex-flow: column;
}

.secondSection .globalModifier {
  width: 80vw;
}

.secondSection .globalModifier span {
  font-size: 1.75rem;
}

@media (max-width: 768px) {
  .secondSection .globalModifier {
    width: 100vw;
    text-align: left;
  }
  .secondSection .globalModifier *,
  .alignmentReveal .globalModifier * {
    justify-content: flex-start !important;
    text-align: left !important;
  }
  .secondSection .globalModifier p span,
  .alignmentReveal .globalModifier p span {
    font-size: 1.25rem;
    line-height: 1.25em;
  }
  .rowitem .globalModifier {
    width: 100% !important;
  }
  .rowitem .globalModifier p span {
    font-size: 1.25rem;
    line-height: 1.25em;
  }
  #referral {
    display: flex;
    flex-flow: column;
  }
  .contactitem .globalModifier h3 {
    font-size: 1.5rem;
  }
  .contactitem .globalModifier {
    width: 100% !important;
  }
}

.swiper-button-next,
.swiper-button-prev {
  color: white !important;
  /* Change arrow color to white */
}

.swiper-pagination {
  display: none;
}

.swiper-slide {
  width: auto;
  /* Ensures Swiper sets the width dynamically */
  flex-shrink: 0;
  /* Prevents slides from shrinking */
  box-sizing: border-box;
}

.slide {
  flex-shrink: 0;
  /* Prevent slides from shrinking */
  width: auto;
  /* Allow Swiper to handle slide sizing */
  box-sizing: border-box;
  /* Ensure padding and margins are included in the width */
}

.swiper-wrapper {
  width: 100vw;
}
