/* empty css */
.marquee {
  display: flex;
  /* height: 100vh; */
  align-items: center;
  z-index: 1;
}

.item {
  font-size: 10vw;
  letter-spacing: -0.05em;
  padding-right: 0.25em;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  color: white;
  /* will-change: transform; */
}

.item div {
  display: inline;
  justify-content: center;
  align-self: center;
  justify-self: center;
}

.item div {
  margin: 0 4vw;
}

.drag {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.bg {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #e6d5b8;
}

a {
  user-select: none;
  /* Standard syntax */
  -webkit-user-select: none;
  /* Safari 3.1+ */
  -moz-user-select: none;
  /* Firefox 2+ */
  -ms-user-select: none;
  /* IE 10+ */
  cursor: grab;
  /* Cursor style */
}

button:focus {
  outline: 1px solid red;
}
