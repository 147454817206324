/* Alignment.module.css */
.divisions {
  position: relative;
  padding: 0 5vw;
  display: flex;
  width: 100vw;
  flex-flow: row;
  flex-direction: column;
  margin-top: 128px;
}

.img {
  position: sticky;
  top: 0;
}

.img img {
  position: sticky;
  height: 200px;
}

.divisions h2 {
  font-size: 4rem;
  font-weight: bold;
  font-stretch: expanded;
}

.divisions p {
  font-size: 1rem;
  line-height: 1.5rem;
}

.divisions p span {
  font-size: 1rem;
}

.divisionsSolo {
  position: relative;
  display: flex;
  flex-direction: row;
  /* Stack items vertically */
}

.stickyContainer {
  position: sticky;
  top: 0;
  width: 100%;
  height: 100vh;
  /* Full viewport height */
  z-index: 1;
}

.imgWrapper {
  position: absolute;
  /* Layer images on top of each other */
  top: 0;
  left: 0;
  width: 50vw;
  height: 400px;
  padding: 64px 0;
  display: flex;
}

.img {
  max-width: 100%;
  width: 100%;
  padding: 0 32px 0 0;
  height: 200px;
  height: auto;
  object-fit: cover;
}

.textContainer {
  position: relative;
  left: 0%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  width: 100vw;
  gap: 24px;
  margin-top: 0vh;
  /* Text starts scrolling after the image */
  z-index: 0;
  /* Below the sticky image */
}

.text {
  top: 0;
  margin-bottom: 100px;
  font-size: 1rem;
  color: #333;
}

.mqText {
  margin-bottom: 48px;
  height: 25vh;
}

@media (max-width: 768px) {
  .textContainer {
    position: relative;
    left: 0%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    width: 100vw;
    gap: 24px;
    margin-top: 0vh;
    /* Text starts scrolling after the image */
    z-index: 0;
    /* Below the sticky image */
  }
  .text {
    margin-bottom: 24px;
  }
  .mqText {
    margin-bottom: 80px;
    height: auto;
  }
}
