@charset "UTF-8";
.revealingTextWrapper {
  position: relative;
  width: 100vw;
  justify-content: center;
  align-items: center;
  padding: 0 5vw;
  color: #fff;
  margin: 24px 0;
}

.positioner {
  width: 100vw;
  text-align: right;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.RevealingTextHeadlineWrapper {
  display: flex;
}

.revealingTextWrapper.right .RevealingTextHeadlineWrapper {
  justify-content: flex-end;
}

.Fixed {
  position: absolute;
  width: 100vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: transparent;
  text-stroke: 0.75px rgba(255, 255, 255, 0.25);
  -webkit-text-stroke: 0.75px rgba(255, 255, 255, 0.25);
  top: 0;
  left: 0;
  padding: 0 5vw;
}

.heroH2 {
  font-size: 2.25rem;
  width: 100%;
  text-align: right;
}

.right .Fixed {
  justify-content: flex-end;
  text-align: right;
  align-items: center;
}

.right .heroH2 {
  text-align: right;
}

.right .positioner {
  text-align: right;
  justify-content: flex-end;
}

.left .Fixed {
  justify-content: flex-start;
  text-align: left;
  align-items: center;
}

.left .heroH2 {
  text-align: left;
}

.left .positioner {
  text-align: left;
  justify-content: start;
}

.fullscreen .Fixed,
.fullscreen .revealingTextWrapper,
.fullscreen .positioner,
.fullscreen .RevealingTextHeadlineWrapper {
  height: 100vh;
}

.small .RevealingText_heroH2 {
  color: red;
}

.small span {
  line-height: 1rem;
  font-size: 1.5rem;
}

.headlineBold {
  font-weight: bold;
  text-stroke: 2px red;
}

.small-value {
  width: 25vw;
  padding: 0;
}

.small-value span {
  font-size: 1.125rem;
  line-height: 1.2rem;
}

.small-value .Fixed,
.small-value .revealingTextWrapper,
.small-value .positioner,
.small-value .RevealingTextHeadlineWrapper {
  width: 25vw;
  padding: 0;
}

.small-value .revealingTextWrapper  {
  padding: 0 !important;
}

.small-value-headline {
  width: 25vw;
  padding: 0;
}

.small-value-headline span {
  font-size: 1.5rem;
  line-height: 1rem;
  font-weight: bold;
}

.small-value-headline .Fixed,
.small-value-headline .revealingTextWrapper,
.small-value-headline .positioner,
.small-value-headline .RevealingTextHeadlineWrapper {
  width: 25vw;
  padding: 0;
}

.small-value-headline .revealingTextWrapper  {
  padding: 0 !important;
}
