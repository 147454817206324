/* Alignment.module.css */
.alignment {
  position: relative;
  height: auto;
  padding-bottom: 100px;
}

.wrapper {
  margin-bottom: 200px;
  margin-top: -50vh;
}

.labelWrapper {
  color: #fff;
  width: 100vw;
  display: flex;
  transform: translateY(10px);
  font-stretch: expanded;
}

.labelSingle {
  width: 25vw;
  text-align: center;
}

@media (max-width: 768px) {
  .wrapper {
    margin-bottom: 48px;
  }
}

.listWrapper {
  margin-top: 80px;
  padding: 0 5vw;
}
