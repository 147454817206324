/* Alignment.module.css */
.divisions {
  position: relative;
  padding: 0 0vw;
  display: flex;
  width: 100vw;
  flex-flow: row;
  flex-direction: column;
  margin-top: 128px;
}

.img {
  position: sticky;
  top: 0;
}

.img img {
  position: sticky;
  height: 200px;
}

.divisions h2 {
  font-stretch: expanded;
}

.divisions p {
  font-size: 1rem;
  line-height: 1.5rem;
}

.divisions p span {
  font-size: 1rem;
}

.divisionsSolo {
  position: relative;
  display: flex;
  flex-direction: row;
  /* Stack items vertically */
}

.stickyContainer {
  position: sticky;
  top: 0;
  width: 100%;
  height: 100vh;
  /* Full viewport height */
  z-index: 1;
}

.imgWrapper {
  position: absolute;
  /* Layer images on top of each other */
  top: 0;
  left: 0;
  width: 50vw;
  height: 400px;
  padding: 64px 0;
  display: flex;
}

.img {
  max-width: 100%;
  width: 100%;
  padding: 0 32px 0 0;
  height: 200px;
  height: auto;
  object-fit: cover;
}

.textContainer {
  position: relative;
  left: 0%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  width: 100vw;
  gap: 24px;
  margin-top: 0vh;
  /* Text starts scrolling after the image */
  z-index: 0;
  /* Below the sticky image */
}

.text {
  top: 0;
  margin-bottom: 24px;
  font-size: 1rem;
  color: #333;
}

.titleWrapper {
  font-size: 8rem;
}

.introText {
  padding: 120px 0 120px 0;
  display: block;
}

.introText h2 {
  font-stretch: normal;
}

.labelWrapper {
  color: #fff;
  width: 100vw;
  display: flex;
  transform: translateY(10px);
  font-stretch: expanded;
}

.labelSingle {
  width: 25vw;
  text-align: center;
}

.listWrapper {
  margin-top: 160px;
  padding: 0 5vw;
}

@media (max-width: 768px) {
  .titleWrapper {
    font-size: 2.5rem;
  }
  .textContainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
