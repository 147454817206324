/* values css */
.LogoWallWrapper {
  height: 100vh;
  position: relative;
}

.LogoWallWrapper:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, black 0%, transparent 48%, black 100%);
}

.LogoWall {
  padding: 25vh 0;
  justify-content: center;
  position: relative;
  z-index: 10;
  height: 50vh;
}

.LogoWall img {
  height: 128px;
}

@media (max-width: 768px) {
  .LogoWallWrapper {
    height: auto;
  }
  .LogoWall {
    height: auto;
    padding: 10vh 0;
  }
  .LogoWall img {
    height: 64px;
  }
}
