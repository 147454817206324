.contact {
  color: #fff;
  display: flex;
  align-items: center;
  padding: 128px 0;
  flex-flow: column;
}

.contactMaxWidth {
  padding: 0 5vw;
  width: 80vw;
}

.contact input,
.contact textarea,
.contact button {
  border-radius: 4px;
}

.contact form {
  margin: 0 auto;
}

.formRow {
  display: flex;
  flex-flow: row;
  gap: 16px;
  margin-bottom: 16px;
}

.formCol {
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 4px;
}

.input,
.inputButton {
  font-size: 16px;
  width: 100%;
  padding: 10px 10px;
  width: 100%;
  outline: none;
  background: #000000;
  color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 0px;
  transition: 0.3s ease;
}

.input:focus,
.inputButton:focus {
  background: #1a1a1a;
  border: 1px solid #d49c4d;
  border-radius: 0px;
}

.input::placeholder,
.inputButton::placeholder {
  color: #727272;
}

input,
textarea {
  font-family: 'Mosvito', sans-serif;
}

.inputButton {
  background-color: #d49c4d;
  outline: none;
  border: none;
  color: #000;
  padding: 16px 0;
  font-weight: bold;
  font-family: 'Mosvito', sans-serif;
  cursor: pointer;
}

.inputButton:hover {
  background-color: #d49c4d;
  filter: brightness(120%);
  transform: scale(0.98);
}

.formHeadline {
  color: rgba(255, 255, 255, 0.5);
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  padding-top: 32px;
  font-size: 1.25em;
  margin: 16px 0 8px 0;
}

.toggleContainer {
  display: flex;
  position: relative;
  width: 300px;
  height: 40px;
  border: 1px solid #fff;
  border-radius: 4px;
  overflow: hidden;
}

.toggleOption {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  background-color: black;
  color: white;
}

/* Toggle Container */
.toggleContainer {
  display: flex;
  position: relative;
  width: 300px;
  height: 40.5px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  overflow: hidden;
}

.toggleContainer .input {
  display: none;
  /* Hide the radio inputs */
}

.toggleOption {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  background-color: black;
  color: white;
}

.toggleOption .input {
  display: none;
  /* Hide the radio inputs */
}

/* Selected (Checked) Styles */
.input:checked + .toggleOption {
  background-color: #d1a354;
  /* Gold color for selected state */
  color: black;
  /* Optional: to match the contrast */
}

label {
  margin-top: 4px;
}

.headlineRow {
  color: #fff;
  font-size: 2.5rem;
  width: 100%;
}

.marqueText h4 {
  display: inline-block;
}

.marqueText svg {
  transform: translateY(-10px);
}

.marqueHeadline {
  font-size: 5rem;
  letter-spacing: 2px;
  display: inline-block;
}

@media (max-width: 768px) {
  .contactMaxWidth {
    padding: 0 5vw;
    width: 100vw;
  }
  .toggleContainer {
    width: 100%;
  }
  .marqueHeadline {
    font-size: 3rem;
    letter-spacing: 2px;
    display: inline-block;
  }
  .marqueText svg {
    transform: translateY(0px);
  }
}
