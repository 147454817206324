/* Main blog container */
.blog {
  color: #fff;
  margin-top: 128px;
}

.blog h1 {
  padding: 0 5vw;
  font-size: 3rem;
  margin-bottom: 24px;
}

.blogWrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
}

/* Custom Slide Styles */
.customSlide {
  background: rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  /* Ensure the slide takes up the full width allocated by Swiper */
  width: 100%;
}

.customSlide img {
  width: 100%;
  height: 320px;
  object-fit: cover;
  object-position: center center;
}

/* Text content inside the slide */
.textWrapper {
  padding: 24px;
}

.date {
  font-size: 6rem;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.7);
  color: transparent;
  margin: 0;
  line-height: 0.75em;
}

.dateSmall {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.boxHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.meta {
  max-width: 10ch;
}

.titleText {
  margin-top: 48px;
}

.titleText h3 {
  margin-bottom: 4px;
}

/* Link styling */
.boxLink {
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  display: block;
  transition: all 0.3s;
}

.boxLink .customSlide {
  margin-bottom: -4px;
  background: rgba(255, 255, 255, 0.1);
  transition: all 0.3s;
}

.boxLink:hover .customSlide {
  background: rgba(255, 255, 255, 0.15);
  color: #d49c4d;
}

.boxLink:hover .date {
  -webkit-text-stroke: 1px #d49c4d;
}

/* Skeleton Loading Styles */
.skeleton {
  background-color: rgba(255, 255, 255, 0.05);
  position: relative;
  overflow: hidden;
}

.skeleton::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.05), transparent);
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

/* Skeleton Blocks for Text */
.skeletonHeader {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.skeletonDate {
  height: 15px;
  width: 20%;
  border-radius: 4px;
}

.skeletonMeta {
  height: 15px;
  width: 50%;
  border-radius: 4px;
}

.skeletonTitle {
  height: 20px;
  width: 70%;
  margin-bottom: 10px;
  border-radius: 4px;
}

.skeletonLine {
  height: 15px;
  width: 100%;
  margin-bottom: 8px;
  border-radius: 4px;
}

.skeletonLineSmall {
  height: 15px;
  width: 60%;
  margin-bottom: 8px;
  border-radius: 4px;
}

/* Skeleton Block for Image */
.skeletonImage {
  height: 200px;
  width: 100%;
  border-radius: 8px;
  margin-top: 10px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .blog {
    margin-top: 48px;
  }
}

/* Swiper specific adjustments */
.swiper-container {
  width: 100%;
  height: auto;
}

.swiper-slide {
  box-sizing: border-box;
  /* Ensure slides don't shrink or grow unexpectedly */
  flex-shrink: 0;
  width: auto;
}

.swiper-button-prev,
.swiper-button-next {
  color: #fff;
}

.swiper-pagination-bullet {
  background: #fff;
}
