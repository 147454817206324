.heroWrapper {
  position: relative;
  z-index: 10;
  height: 100vh;
  width: 100vw;
  justify-content: flex-end;
  display: flex;
  flex-flow: column;
  padding: 0 5vw;
  pointer-events: none;
  color: #fff;
}

.heroH1 {
  max-width: 26ch;
  font-size: 2.5rem;
  font-weight: 300;
  margin-bottom: 12.5vh;
  line-height: 1.25em;
}

.heroSubText {
  color: #d49c4d;
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 2rem;
}
