/* WhereWeAre.module.css */
.containerWrapper {
  display: flex;
  justify-content: flex-end;
}

.container {
  background-color: #000;
  color: #d3d3d3;
  /* Light grey text */
  padding: 0 5vw;
  right: 0;
  max-width: 80vw;
  /* Limit the width to 60vw */
}

.title {
  font-size: 3rem;
  text-align: right;
  margin-bottom: 1.5rem;
}

.locationCard {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  border-bottom: 1px solid #333;
}

.city {
  flex: 1;
  font-weight: bold;
  text-align: left;
  margin-right: 20vw;
  display: block;
  /* No line breaks in the city */
}

.timeWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.timeLabel {
  font-size: 0.9rem;
  color: #a9a9a9;
  /* Darker grey for the label */
  margin-bottom: 0.3rem;
}

.time {
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  /* White for the time */
}

.addressWrapper {
  flex: 1;
  text-align: right;
  display: flex;
  width: 300px;
  flex-direction: column;
  /* Stack label and address vertically */
}

.addressLabel {
  font-size: 0.9rem;
  color: #a9a9a9;
  /* Darker grey for the label */
  margin-bottom: 0.3rem;
}

.address {
  font-size: 0.95rem;
  line-height: 1.4;
  /* Adds spacing between address lines */
  white-space: pre-line;
  /* Ensure line breaks in the address are respected */
}

@media (max-width: 768px) {
  .containerWrapper {
    justify-content: flex-start;
  }
  .container {
    background-color: #000;
    color: #d3d3d3;
    /* Light grey text */
    padding: 0 5vw;
    right: 0;
    width: 100%;
    max-width: 100vw;
    /* Limit the width to 60vw */
  }
  .locationCard {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    align-items: start;
    justify-items: start;
    width: 100%;
  }
  .title {
    text-align: left;
  }
  .addressWrapper {
    text-align: left;
    width: 100%;
  }
  .city {
    margin-right: 0;
  }
  .timeWrapper {
    justify-self: end;
  }
  .time {
    text-align: right;
  }
}
